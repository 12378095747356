@import '~@boclips-ui/styles/src/colors';
@font-family: 'Rubik', Helvetica Neue, Helvetica, Arial, sans-serif;

:root {
  --blue-100: @blue-100;
  --blue-200: #edf2f7;
  --blue-400: @blue-400;
  --blue-500: @blue-500;
  --blue-600: @blue-600;
  --blue-700: @blue-700;
  --blue-800: @blue-800;
  --blue-900: @blue-900;

  --gray-100: @gray-100;
  --gray-400: @gray-400;
  --gray-500: @gray-500;
  --gray-600: @gray-600;
  --gray-700: @gray-700;
  --gray-800: @gray-800;
  --gray-900: @gray-900;

  --red-error: #df0000;

  --yellow-warning: @warning-bg;
  --yellow-warning-border: @warning;
}
