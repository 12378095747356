@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'vars.less';
@import '~@boclips-ui/styles/src/colors';
@import '~@boclips-ui/styles/src/focus';

@layer base {
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
  }

  img,
  video {
    max-width: none !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  main,
  a,
  button {
    #focus;
  }

  .container {
    min-width: 1280px;
  }

  div[data-radix-popper-content-wrapper] {
    z-index: 99999 !important;
  }
}

@layer utilities {
  .truncate {
    white-space: break-spaces;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  font-family: @font-family !important;
}

#hs-eu-decline-button {
  color: @blue-800 !important;
}

#hs-eu-confirmation-button {
  color: @white !important;
}
